<template>
  <div>
    <!-- header -->
    <v-parallax src = "../assets/Geometric_Parallax_2.png" height="500">
        <v-container fluid style="padding: 0px;">
            <v-row class="block-center">
                <div class="page-title">
                    Tanner Paschal
                </div>
                <div class="page-subtitle">
                    - Software Developer -
                </div>
            </v-row>
        </v-container>
    </v-parallax>

    <!-- Interests -->
    <v-card class="page-card">
    <v-container>
        <v-row align="center" justify="center">
            <v-col cols="12" md="3">
                <div class="page-title-2">My Interests</div>
                <hr>
                <div class="page-title-3">Creativity</div>
            </v-col>
            <v-col text-align="center" cols="12" md="6">
                <div class="page-text">
                    I'm a full-stack developer with a special passion for web development.
                </div>
                <div class="page-text">
                    The driving force behind my work ethic is my love of problem solving and finding creative solutions. I value collaboration because a team working together is greater than the sum of its parts.
                </div>
            </v-col>
        </v-row>
    </v-container>
    </v-card>

    <!-- Skillset -->
    <v-card class="page-card">
    <v-container >
        <v-row align="center" justify="center">
            <v-col cols="12" md="3">
                <div class="page-title-2">My Skillset</div>
                <hr>
                <div class="page-title-3">Software Development</div>
            </v-col>
            <v-col cols="12" md="6" align="center">
                <div class="page-text">
                    I have professional experience with the following
                </div>
                <v-row class="page-text">
                    <v-col align="center">
                        <div>
                            <v-img max-height="50" max-width="50" aspect-ratio="1" src="../assets/VueLogo.png"></v-img>
                            vue.js
                        </div>
                    </v-col>
                    <v-col align="center">
                        <div>
                            <v-img max-height="50" max-width="50" aspect-ratio="1" src="../assets/DotNetLogo.png"></v-img>
                            .Net (C#)
                        </div>
                    </v-col>
                    <v-col align="center">
                        <div>
                            <v-img max-height="50" max-width="50" aspect-ratio="1" src="../assets/DB2Logo.png"></v-img>
                            IMB DB2 Database
                        </div>
                    </v-col>
                    <v-col align="center">
                        <div>
                            <v-img max-height="50" max-width="50" aspect-ratio="1" src="../assets/DatastageLogo.png"></v-img>
                            IMB Datastage
                        </div>
                    </v-col>
                    <v-col align="center">
                        <div>
                            <v-img max-height="50" max-width="50" aspect-ratio="1" src="../assets/SalesforceLogo.png"></v-img>
                            Salesforce
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
    </v-card>

    <!-- About Me -->
    <v-card class="page-card">
        <v-container fluid>
            <v-row align="center" justify="center">
                <v-col cols="12" md="6" lg="3">
                    <v-img max-height="684" max-width="513" aspect-ratio=".75" src="../assets/Gear.jpg" class="block-center"></v-img>
                </v-col>
                <v-col text-align="center">
                    <div class="page-title-2">About Me</div>
                    <hr>
                    <div class="page-text">
                        I was born and raised in Fort Smith, Arkansas, and received my Bachelors Degree of Computer Science from the University of Arkansas.
                        <img height="150" width="150"  src="../assets/razorback_changing.gif" align="center" class="block-center">
                    </div>
                    <div class="page-text">
                        When I'm not at work or tinkering with a side-project I enjoy hiking, camping, cooking, and playing basketball. 
                    </div>
                    <div class="page-text">
                        I'm also a serial hobbyist who enjoys trying new things!
                        So far, I've tried: biking, running, rock-climbing, blacksmithing, carpentry, writing, video game development, and a lot more. 
                        If you have any suggestions, I'll try to add it to the list.
                    </div>
                    <div class="page-text" justify="bottom">
                        <a href="http://www.linkedin.com/in/tanner-paschal-bb80a0159"><img src="../assets/LILogo.jpg" alt="linkedin" style="width:50px;height:50px;"></a>
                        <div>tannerspaschal@gmail.com</div>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
  </div>
</template>

<script>

export default {
  name: 'TannerHome',
  components: {
    
  }
}
</script>

<style scoped>
.page-header {
    display: block;
    
}
.page-title {
    text-align: center;
    font-size: 50px !important;
    font-family:'Inconsolata','IBM Plex Mono', 'Roboto Mono', sans-serif;
    color: black;
}
.page-subtitle {
    text-align: center;
    font-size: 15px !important;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: black;
}
.page-title-2 {
    text-align: center;
    font-size: 40px !important;
    font-family:'Inconsolata','IBM Plex Mono', 'Roboto Mono', sans-serif;
    margin: 0px;
    padding: 0px;
}
.page-title-3 {
    text-align: center;
    font-size: 15px !important;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.page-card {
    margin: 60px;
}
.page-text {
    margin: 30px;
    font-size: medium;
}
.block-center {
    display: block; margin-left: auto; margin-right: auto;
}

hr {
    width:50%;
    margin:auto;
}
</style>